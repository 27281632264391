import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/mark/di-net2/src/components/layout.js";
import Sidebar from "../../../../components/sidebar";
import Outcomeitem from "../../../../components/outcomelist";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`>>FastForward>>`}</h1>
    <h4>{`June 17–23, 2012 | Vinalhaven, ME`}</h4>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px",
          "margin": "5vh 0",
          "objectFit": "contain"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAgQA/8QAFgEBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAGc0Kajyx//xAAZEAADAQEBAAAAAAAAAAAAAAAAAQIRAxP/2gAIAQEAAQUCUaeUD41qGi29/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Bh//EABkQAAMAAwAAAAAAAAAAAAAAAAABIRARMf/aAAgBAQAGPwKkI1jZ0//EABsQAAMBAQADAAAAAAAAAAAAAAABESFBMVFx/9oACAEBAAE/IUpIY2ZV7pzF8GqvoQnjRrzH/9oADAMBAAIAAwAAABBX3//EABYRAQEBAAAAAAAAAAAAAAAAAAARIf/aAAgBAwEBPxCtf//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAgEBPxCmR//EABwQAQADAAIDAAAAAAAAAAAAAAEAESExQVFhgf/aAAgBAQABPxCqCO7MSB72C/BOO9cFXNCZSg7+wIaBvGhfcs1GdT//2Q==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": ">>FastForward>>",
          "title": ">>FastForward>>",
          "src": "/static/36f8cd1ad31fc8a9d2e17116d6ab73dc/e5166/IMG_3773.jpg",
          "srcSet": ["/static/36f8cd1ad31fc8a9d2e17116d6ab73dc/f93b5/IMG_3773.jpg 300w", "/static/36f8cd1ad31fc8a9d2e17116d6ab73dc/b4294/IMG_3773.jpg 600w", "/static/36f8cd1ad31fc8a9d2e17116d6ab73dc/e5166/IMG_3773.jpg 1200w", "/static/36f8cd1ad31fc8a9d2e17116d6ab73dc/d9c39/IMG_3773.jpg 1800w", "/static/36f8cd1ad31fc8a9d2e17116d6ab73dc/df51d/IMG_3773.jpg 2400w", "/static/36f8cd1ad31fc8a9d2e17116d6ab73dc/99a54/IMG_3773.jpg 4368w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <Sidebar mdxType="Sidebar">
    <Outcomeitem link="https://archive.designinquiry.net/contributions/designinquiry-fastforward-remark/" title="Introduction by Peter Hall" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/fast-forward-daily/" title="Fast Forward Daily by Margo Halverson" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/slowingextended-time/" title="Slowing/Extending Time by Leanne Elias" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/immediate-outcome-of-a-letterform-workshop/" title="Immediate Outcome of a Letterform Workshop by Mark Jamra" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/measuring-experiential-time/" title="Measuring Experiential Time by Margo Halverson and Peter Hall" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/more-words-on-fastforward/" title="PAST<<(((?)))>>FUTURE by Lindsey Culpepper" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/the-daily-practice-league/" title="The Daily Practice League by Gail Swanlund" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/immediate-outcomes-of-a-weaving-workshop/" title="Immediate Outcome of a Weaving Workshop by Rebecca Keyel" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/immediate-outcomes/" title="Immediate Outcomes by Galrielle Esperdy" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/3225/" title="Immediate Outcomes by Anita Cooney" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/rough-raw-inconsistent-and-unpopular/" title="Rough, Raw, Inconsistent, and Unpopular by Sean Carnegie" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/2012-fastforward-exhibition/" title="FastForward Exhibition by Mark Jamra" mdxType="Outcomeitem" />
    <Outcomeitem link="https://archive.designinquiry.net/contributions/wrap-up-by-peter-hall/" title="Wrap-Up by Peter Hall" mdxType="Outcomeitem" />
 
    </Sidebar>
    <p><strong parentName="p">{`The quickening pace of life calls for a rethink of time. With a deadline approaching or bombarded by the unforeseen, we are often forced to cut corners, think on our feet, fly by the seat of our pants. In the midst of these crises, time, which seemed so linear, fixed, immutable, becomes elastic: we find space to breathe, think, laugh, pull off unexpected feats. It’s while we are fast-forwarding, oddly, that new insights and possibilities can come to light. But the phrase >>fast-forward>> also suggests we have an end point in mind. FastForward ten or 10,000 years and what do we imagine we will have left behind?  How might this longer view of time shift the way we make design decisions? DesignInquiry 2012: >>FastForward>> finds a wrinkle in time on a remote island in Maine to explore speed, time-being, and how we might redesign the future: through workshops, presentations, discussion and digestion.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      